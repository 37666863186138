// Dependencies
import React, { useState } from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// ...
// Components
import Layout from "../components/Layout";
import NewsLink from "../components/NewsLink";
import NewsOverlay from "../components/NewsOverlay";

const NewsPage = ({ data, pageContext }) => {

	// Content
	const allNews = data.allContentfulNews.nodes;

	// UX
	const [activeNews, setNews] = useState(null);

	// UI
	const handleClose = () => {
		setNews(null)
	} 

	return (
		<Layout title="News" headerTitle={"News"}>

			<section className="news-list">
				{allNews && allNews.map((entry) => {
					return (
						<NewsLink 
							className="guttersx2" 
							entry={entry}
							overlayOpen={activeNews}
							openNewsOverlay={setNews}
							key={entry.id} 
						/>
					)
				})}
			</section>

			<NewsOverlay 
				entry={activeNews} 
				handleClose={handleClose} 
			/>
  	</Layout>
	)
}

NewsPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default NewsPage

export const NewsPagePageQuery = graphql`
  query NewsPageQuery {
    allContentfulNews(
			sort: {fields: date, order: DESC}
			filter: {contentful_id: {ne: "3KwmsxXCQwySMK4l7fQaew"}}
		) {
			nodes {
				...ContentfulNewsFragment
			}
		}
  }
`
